import React from 'react'
import "./App.css";
// import { useStatus } from '@featurevisor/react';

const App = () => {

  // const { isReady }  = useStatus();

  // if(!isReady){
  //   return <p>Loding The Content</p>
  // }

  return (
    <div className='App'>
      <h1>Hello World</h1>
    </div>
  )
}

export default App